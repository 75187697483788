.flexbox {
    display:flex;
    flex-direction:row;
    align-items:center;
    padding: 16px 8px;
}
.iconStyle {
    width:24px;
    height:24px;
    object-fit:contain;
}
.content {
    padding-bottom:16px;
    padding-left:24px;
    padding-right:24px;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:flex-start;
}
.link {
    cursor: pointer;
}
.link:hover {
    text-decoration: underline;
}
.error {
    color: #f00 !important ;
}
.success {
    color: #1DE9B6 !important;
}
.drawerTitle {
    font-family:Lato;
    font-weight:900;
    font-size:1.5625rem;
    color:#fff;
    letter-spacing: -0.32px;
    flex:1;
:undefined;
}
