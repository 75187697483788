.side-bar {
  width: 222px;
  display: flex;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;

  @media (max-width: 750px) {
    width: 100%;
  }

  .side-bar-item-container {
    margin-left: 32px;
    width: 100%;
    margin-right: 32px;

    @media (max-width: 480px) {
      padding-left: 16px;
      padding-right: 16px;
      margin-left: unset;
      margin-right: unset;
    }
  }
}

.social-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
  padding-bottom: 1rem;
  padding-top: 1rem;
  width: 100%;

  .firstSocialIcon {
    float: left;
    cursor: pointer;
    bottom: 0;
  }

  .social-icons {
    float: left;
    margin-left: 7%;
    cursor: pointer;
  }
}

.side-bar-container {
  background-image: url("https://dashradio-files.s3.amazonaws.com/temporary/valentines-bg.jpg");
  background-size: cover;
  background-position-x: center;
  height: 100vh;

  .logo-container {
    padding-left: 32px;
    padding-right: 32px;
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 14%;

    @media (max-width: 480px) {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
}
